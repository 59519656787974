import {graphql} from 'gatsby'
import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import '../styles/pages/blog_post.less'
import {Banner, ErrorBoundary, Image, Layout, PageLayout, ProductHighlights, RichText, Text, Title, UserInfo} from '/src/components'
import {TEXT_PAGE_WIDTH} from '/src/constants'
import {insertArray, insertInRichText} from '/src/helpers'

const richTextImages = {}
const PRODUCTS = [['all_in_one']]

const options = {
	paragraph: (_, children) => <Text lineHeight={2.5}>{children}</Text>,
	'heading-3': (_, children) => <h3>{children}</h3>,
}

const InnerPage = ({data: {blogPost, featureHighlight}}) => {
	const {image, author, publishedAt, body} = blogPost
	const {name, avatar} = author

	// explicitly pass false to prevent showing the subtitle when not present in Contentful.
	const subtitle = blogPost.subtitle || false

	const {references = []} = body
	references.map(
		reference =>
			(richTextImages[reference.contentful_id] = {
				image: reference.gatsbyImageData,
				alt: reference.title,
			}),
	)

	const raw = insertInRichText(body.raw, insertArray)
	return (
		<ErrorBoundary>
			<Layout maxWidth="100%" width={TEXT_PAGE_WIDTH} direction="vertical" horizontal="center">
				<Banner type="blog_post" {...blogPost} margin="60 - 40 -" firstButtonSolid={false} subtitle={subtitle} />
				<Image image={image} width="100%" />
				<UserInfo marginTop={40} nameColor="black" name={name} image={avatar} subtext={publishedAt} />
				<RichText content={raw} richTextImages={richTextImages} options={options} fullWidth />
				<ProductHighlights items={PRODUCTS[0]} highlights={featureHighlight} />
				<Banner type="ei_curious" margin="120 -" />
			</Layout>
		</ErrorBoundary>
	)
}

const Page = ({data}) => {
	const {seoTitle, seoDescription} = data.blogPost
	return (
		<PageLayout name="blog-post" seoTitle={seoTitle} seoDescription={seoDescription}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ($slug: String!, $locale: String) {
		contentfulBlogPost(slug: {eq: $slug}) {
			title
			subtitle
			seoTitle
			seoDescription
			image {
				gatsbyImageData
			}
			body {
				raw
				references {
					gatsbyImageData(layout: CONSTRAINED, quality: 80, formats: [WEBP, AUTO], placeholder: BLURRED)
					contentful_id
					title
				}
			}
			publishedAt(formatString: "DD MMMM, YYYY")
			author {
				name
				avatar {
					gatsbyImageData
				}
			}
		}
		allContentfulFeatureHighlight(filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					uuid
					paragraph {
						paragraph
					}
					cta
					name
					previews {
						gatsbyImageData
					}
					buttonLink
					buttonText
					label
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
